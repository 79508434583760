import React, { useState } from 'react';
import { IconOwlChat } from '@darwinex/components-library';
import classNames from 'classnames';

import styles from './ChatBot.module.scss';

export const ChatBot = () => {
    const [visible, setVisible] = useState<boolean>();
    const onClick = () => {
        setVisible((p) => (p === undefined ? true : !p));
    };
    return (
        <div className={styles.container}>
            <div className={styles.toggle} onClick={onClick}>
                <IconOwlChat />
            </div>
            <iframe
                src="https://www.chatbase.co/chatbot-iframe/SP5t4dLR2TC4ZA8T46AII"
                title="Zero AI assistant"
                width="100%"
                className={classNames(styles.iframe, visible && styles.fadeIn, visible === false && styles.fadeOut)}
            />
        </div>
    );
};
