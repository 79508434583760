import React, { useState } from 'react';

import { T_ProfileResponse } from '~/pages/api/profile';
import useWindowSize from '~/hooks/useWindowSize';

import Navbar from '~/storybook/components/common/navbar/Navbar';
import Footer from '~/storybook/components/common/footer/Footer';

import styles from '~/styles/layout/HomeLayout.module.scss';

import Nullable from '~/types/Nullable';
import { ChatBot } from '~/components/common/chat-bot/ChatBot';
import MenuSide, { MenuDataArray } from './MenuSide';
import { HomeLayoutContext } from './HomeLayoutContext';

type Props = {
    children?: React.ReactNode;
    onLogin: () => void;
    onRegister: () => void;
    onPlatform: () => void;
    profile?: T_ProfileResponse;
    doLogout: () => void;
    openLink: (path: string) => void;
    logged: boolean | null;
    noMenu?: boolean;
    menuData?: MenuDataArray;
    onChangeLocale?: (newLocale: string) => void;
    localesAvailable?: string[];
    locale?: string;
    changeAsPath: (asPath: string) => void;
    showFCAText?: Nullable<boolean>;
    showDiscordIcon?: Nullable<boolean>;
};

const HomeLayout = (props: Props) => {
    const {
        children,
        noMenu,
        onLogin,
        onRegister,
        profile,
        doLogout,
        openLink,
        logged,
        onPlatform,
        menuData,
        onChangeLocale,
        localesAvailable,
        locale,
        changeAsPath,
        showFCAText,
        showDiscordIcon,
    } = props;

    const { width } = useWindowSize();
    const responsive = width < 992;

    const [showMenu, setshowMenu] = useState(false);

    const toggleMenu = () => {
        if (responsive) {
            setshowMenu(!showMenu);
        }
    };

    return (
        <HomeLayoutContext.Provider
            value={{
                openLink,
                profile,
            }}
        >
            {
                <div className={styles.layout} id="home-layout-container">
                    <Navbar
                        onLogin={onLogin}
                        onRegister={onRegister}
                        logged={logged}
                        onPlatform={onPlatform}
                        doLogout={doLogout}
                        toggleMenu={toggleMenu}
                        showMenu={showMenu}
                    />
                    <div className={styles.layout_body}>
                        <div className={styles.layout_children}>
                            {!noMenu && menuData && <MenuSide menuData={menuData} changeAsPath={changeAsPath} />}
                            {!noMenu && <div className={styles.layout_content}>{children}</div>}
                            {noMenu && <div className={styles.layout_content_home}>{children}</div>}
                            <Footer
                                onChangeLocale={(newLocale: string) => {
                                    if (typeof onChangeLocale !== 'undefined') {
                                        onChangeLocale(newLocale);
                                    }
                                }}
                                localesAvailable={localesAvailable}
                                locale={locale}
                                showFCAText={showFCAText}
                                showDiscordIcon={showDiscordIcon}
                            />
                        </div>
                    </div>
                    <ChatBot />

                    {/* {menuChildren && <div className={styles.layout_backdrop} onClick={() => setMenuChildren('')}></div>} */}
                </div>
            }
        </HomeLayoutContext.Provider>
    );
};
export default HomeLayout;
