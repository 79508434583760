import { assertString } from '~/utils/strings';
import { CountryInfo } from '~/types/CountryInfo';
import Nullable from '~/types/Nullable';

const FORBIDDEN_COUNTRIES_FOR_FCA_INFO = ['es'];
const FORBIDDEN_COUNTRIES_FOR_SHOW_INVEST_BUTTON = ['es'];
const FORBIDDEN_COUNTRIES_FOR_SHOW_DISCORD_LINK = ['es'];
const COUNTRIES_WITH_DISCOUNT_IN_FUTURES_ACCOUNT = ['es'];
const COUNTRIES_THAT_CANNOT_CHANGE_TAX_RESIDENCE_COUNTRY = ['es'];
const COUNTRIES_UNDER_CNMV = ['es'];

/**
 *
 * @param userCountries
 * User countries includes:
 * 1 - Country where the user is located (by ip address)
 * 2 - Country used by the user in the registration form
 * 3 - Country used by the user in the KYC form
 */
const isUserForbiddenFromViewingFCAInfo = (userCountries: Array<unknown>): boolean =>
    FORBIDDEN_COUNTRIES_FOR_FCA_INFO.some((c) =>
        userCountries.filter(assertString).some((p) => new RegExp(c, 'ig').test(p)),
    );

const isUserForbiddenFromViewingInvestButton = (userCountries: Array<unknown>): boolean =>
    FORBIDDEN_COUNTRIES_FOR_SHOW_INVEST_BUTTON.some((c) =>
        userCountries.filter(assertString).some((p) => new RegExp(c, 'ig').test(p)),
    );

const isUserForbiddenFromViewingDiscordLink = (userCountries: Array<unknown>): boolean =>
    FORBIDDEN_COUNTRIES_FOR_SHOW_DISCORD_LINK.some((c) =>
        userCountries.filter(assertString).some((p) => new RegExp(c, 'ig').test(p)),
    );

const userHasDiscountInFuturesAccount = (countries: Array<unknown>): boolean =>
    COUNTRIES_WITH_DISCOUNT_IN_FUTURES_ACCOUNT.some((c) =>
        countries.filter(assertString).some((p) => new RegExp(c, 'ig').test(p)),
    );

const taxResidenceModificationIsForbidden = (countries: Array<unknown>): boolean =>
    COUNTRIES_THAT_CANNOT_CHANGE_TAX_RESIDENCE_COUNTRY.some((c) =>
        countries.filter(assertString).some((p) => new RegExp(c, 'ig').test(p)),
    );

const someCountryBelongsToCNMV = (countries: Array<unknown>): boolean =>
    COUNTRIES_UNDER_CNMV.some((c) => countries.filter(assertString).some((p) => new RegExp(c, 'ig').test(p)));

/**
 *
 * @param countries List of possible countries to select
 * @param requestOriginCountry Country where the user is located (by ip address)
 * @param registerCountry (Optional) Country used by the user in the registration form
 * @returns List of countries that the user can select as a tax residence country
 * @Description This functions returns the list of countries that the user can select as a tax residence country:
 * 1 - If the user is located (by IP) in a country that cannot change the tax residence country,
 * the list will be filtered to only include that country
 * 2 - If the user is located (by IP) in a country that can change the tax residence country,
 * the list will be filtered to exclude the countries that cannot change the tax residence country
 */
const getTaxResidenceAllowedCountries = (
    countries: Array<CountryInfo>,
    requestOriginCountry: Nullable<string>,
    registerCountry?: Nullable<string>,
    includeRegisterCountry?: Nullable<boolean>,
): Array<CountryInfo> => {
    if (requestOriginCountry !== null) {
        return COUNTRIES_THAT_CANNOT_CHANGE_TAX_RESIDENCE_COUNTRY.includes(requestOriginCountry.toLowerCase())
            ? countries.filter(
                  (country) =>
                      country.id === requestOriginCountry ||
                      (registerCountry !== undefined && registerCountry !== null && country.id === registerCountry),
              )
            : countries.filter(
                  (country) =>
                      !COUNTRIES_THAT_CANNOT_CHANGE_TAX_RESIDENCE_COUNTRY.includes(country.id.toLowerCase()) ||
                      (includeRegisterCountry !== undefined &&
                          includeRegisterCountry === true &&
                          registerCountry !== undefined &&
                          registerCountry !== null &&
                          country.id === registerCountry),
              );
    }
    return countries;
};

export {
    isUserForbiddenFromViewingFCAInfo,
    isUserForbiddenFromViewingInvestButton,
    isUserForbiddenFromViewingDiscordLink,
    userHasDiscountInFuturesAccount,
    taxResidenceModificationIsForbidden,
    getTaxResidenceAllowedCountries,
    someCountryBelongsToCNMV,
};
