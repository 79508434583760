import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import styles from '~/styles/layout/MenuSide.module.scss';
import { IconChevron } from '@darwinex/components-library';

interface IMenuData {
    title: string;
    items: {
        title: string;
        children: {
            title: string;
            link: string;
            mode?: string;
        }[];
    }[];
}

export type MenuDataArray = IMenuData[];

interface Props {
    menuData: MenuDataArray;
    changeAsPath: (asPath: string) => void;
}

const MenuSide = (props: Props) => {
    const { menuData, changeAsPath } = props;
    const [isOpenMenu, setIsOpenMenu] = useState(true);
    const [itemSelected, setItemSelected] = useState(
        menuData[0].items.length > 0 ? menuData[0].items[0].children[0].link : null,
    );

    const openExternalLink = (link: string) => {
        window.open(link, 'Darwinex Zero');
    };

    const openLink = (link: string, mode?: string) => {
        if (link.startsWith('https')) {
            openExternalLink(link);
        } else {
            const element = document.querySelector(`section[data-anchor=${link}]`) as HTMLElement | null;
            const container = document.getElementById('home-layout-container');
            if (container && element) {
                changeAsPath(`#${link}`);
            } else if (container && mode) {
                changeAsPath(`#${link}`);
            }
        }
    };

    const scrollHandler = () => {
        const navbarHeight = document.getElementById('navbar')?.offsetHeight ?? 0;
        const elements = document.querySelectorAll<HTMLElement>(`section[data-anchor]`);
        elements.forEach((element) => {
            if (element.getBoundingClientRect().top <= navbarHeight) {
                setItemSelected(element.dataset.anchor as string);
            }
        });
    };

    useEffect(() => {
        // const scrollElement = document.querySelector('.scroller-container > div > div:nth-child(1)');
        const container = document.getElementById('home-layout-container');
        container?.addEventListener('scroll', scrollHandler, false);
        return () => container?.removeEventListener('scroll', scrollHandler);
    }, []);

    /**
     * When we change from one route to another, menu and selected item needs to be updated
     */
    useEffect(() => {
        if (
            menuData.length > 0 &&
            menuData[0].items.length > 0 &&
            menuData[0].items[0].children.length > 0 &&
            menuData[0].items[0].children[0].link
        ) {
            setItemSelected(menuData[0].items[0].children[0].link);
        }
    }, [JSON.stringify(menuData)]);

    return (
        <div className={classNames(styles.menu)}>
            <div className={styles.menu__groups}>
                {menuData.map((group, indexGroup) => (
                    <div className={styles.menu__group} key={group.title}>
                        {group.items.map((item, indexItem) => (
                            <div
                                className={classNames(
                                    isOpenMenu && styles.open,
                                    indexGroup === 0 && indexItem === 0 && styles.menu__header,
                                )}
                                onClick={() => setIsOpenMenu(!isOpenMenu)}
                                key={item.title}
                                aria-hidden="true"
                            >
                                <span className={classNames(styles.menu__button)}>
                                    {item.title}
                                    <IconChevron
                                        width={12}
                                        height={12}
                                        className={classNames(
                                            isOpenMenu ? styles['chevron-open'] : styles['chevron-closed'],
                                        )}
                                    />
                                </span>
                                {item.children && (
                                    <div className={classNames(styles.menu__container)}>
                                        {item.children.map((children) => (
                                            <button
                                                type="button"
                                                className={classNames(
                                                    styles.menu__item,
                                                    isOpenMenu &&
                                                        children.link === itemSelected &&
                                                        styles.menu__item__active,
                                                    isOpenMenu && styles.openMenu,
                                                    !isOpenMenu && styles.closeMenu,
                                                    isOpenMenu && item.children.length > 7 && styles.openMenuSlow,
                                                )}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    openLink(children.link, children.mode && children.mode);
                                                }}
                                                key={children.link}
                                                data-anchor={children.link}
                                            >
                                                <span>{children.title}</span>
                                            </button>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MenuSide;
