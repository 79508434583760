import React, { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';

import classNames from 'classnames';

import { useI18N } from '~/contexts/NextI18NProvider';
import { NavbarLinkType, NavbarLinksType } from '~/types';

import { Button, IconCloseRounded, IconLogOut, IconMenuMobile, IconUser } from '@darwinex/components-library';

import IconLogoDarwinexZero from '~/storybook/icons/IconLogoDarwinexZero';
import IconLogoDarwinexZeroMobile from '~/storybook/icons/IconLogoDarwinexZeroMobile';
import { HomeLayoutContext, HomeLayoutContextProps } from '~/storybook/layout/HomeLayoutContext';

import styles from './Navbar.module.scss';

interface Props {
    onLogin: () => void;
    onRegister: () => void;
    onPlatform: () => void;
    doLogout: () => void;
    logged: boolean | null;
    toggleMenu: () => void;
    showMenu: boolean;
}

const Navbar = ({ onLogin, onRegister, onPlatform, logged, doLogout, toggleMenu, showMenu }: Props) => {
    const { M, ULink } = useI18N();

    const homeLayoutContext = React.useContext(HomeLayoutContext) as HomeLayoutContextProps;
    const { openLink, profile } = homeLayoutContext;

    const [isProfileActive, setProfileActive] = useState(false);
    const profileMenu = useRef<HTMLDivElement>(null);

    const toggleProfile = () => {
        setProfileActive(!isProfileActive);
    };

    const getUserAvatar = () => {
        if (profile?.avatarUrl) {
            return (
                <Image
                    quality={100}
                    alt={profile?.username as string}
                    title={profile?.username as string}
                    src={profile?.avatarUrl}
                    width={40}
                    height={40}
                    className={classNames(styles['navbar__profile-img'])}
                    onClick={toggleProfile}
                    style={{
                        // maxWidth: '100%',
                        height: 'auto',
                    }}
                />
            );
        }
        return (
            <IconUser
                onClick={toggleProfile}
                color="var(--dxcl-c-tertiary-300)"
                className={classNames(styles['navbar__profile-avatar'])}
            />
        );
    };

    useEffect(() => {
        function handleClickOutside(e: MouseEvent) {
            if (profileMenu.current && !profileMenu.current.contains(e.target as Node)) {
                setProfileActive(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    type NavbarLinksProps = {
        idX?: string;
        className?: string;
    };

    const NavbarLinks = ({ idX, className }: NavbarLinksProps) => {
        const links: NavbarLinksType = [
            {
                id: 0,
                label: M('components.navbar-home.whyZero'),
                link: '/why-us',
            },
            {
                id: 1,
                label: M('components.navbar-home.capitalAllocation'),
                link: '/capital-allocation',
            },
            {
                id: 2,
                label: M('components.navbar-home.strategy'),
                link: '/your-darwin-index',
            },
            {
                id: 3,
                label: M('components.navbar-home.assets'),
                link: '/assets',
            },
            {
                id: 4,
                label: M('components.navbar-home.pricing'),
                link: '/pricing',
            },
            {
                id: 5,
                label: M('components.navbar-home.partners'),
                link: '/partners',
            },
        ];

        return (
            <ul className={className} id={idX}>
                {links.map(({ id, link, label }: NavbarLinkType) => (
                    <li onClick={toggleMenu} key={id}>
                        <ULink href={link} className={classNames(styles.navbar__links)}>
                            {label}
                        </ULink>
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <header className={classNames(styles.navbar)} id="navbar">
            <div className={classNames(styles['navbar--right'])}>
                <div className={classNames(styles.navbar__top)}>
                    {showMenu ? (
                        <IconCloseRounded
                            className={classNames(styles.navbar__top__button, styles.navbar__toggle)}
                            width="24"
                            height="24"
                            onClick={toggleMenu}
                        />
                    ) : (
                        <IconMenuMobile
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            className={classNames(styles.navbar__top__button, styles.navbar__toggle)}
                            onClick={toggleMenu}
                        />
                    )}
                    <Link href="/">
                        <IconLogoDarwinexZeroMobile className={classNames(styles['navbar__logo--mobile'])} />
                    </Link>
                    <Link href="/">
                        <IconLogoDarwinexZero className={classNames(styles['navbar__logo--desk'])} />
                    </Link>
                </div>
            </div>
            <nav className={showMenu ? classNames(styles['show-menu'], styles.menu) : classNames(styles.menu)}>
                <NavbarLinks className={classNames(styles.navbar__nav)} idX="navbar__nav" />
            </nav>
            <div className={classNames(styles['navbar--left'])}>
                <NavbarLinks className={classNames(styles.navbar__nav)} idX="navbar__nav" />
            </div>
            {logged !== null && (
                <div className={classNames(styles.navbar__buttons)}>
                    {!logged ? (
                        <>
                            <Button size="z-xxs" border="rect" type="button" variant="secondary" onClick={onLogin}>
                                {M('components.navbar-home.btnLogIn')}
                            </Button>

                            <Button size="z-xxs" border="rect" type="button" variant="primary" onClick={onRegister}>
                                {M('components.navbar-home.btnSignUp')}
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button size="z-xxs" border="rect" type="button" variant="secondary" onClick={onPlatform}>
                                {M('components.navbar-home.platform')}
                            </Button>

                            <div className={classNames(styles.navbar__profile)} ref={profileMenu}>
                                {getUserAvatar()}
                            </div>
                        </>
                    )}
                </div>
            )}
            <div className={isProfileActive ? classNames(styles['show-profile']) : classNames(styles['profile-menu'])}>
                <ul>
                    {/* <li>
                        <Link href="/" className={classNames(styles.navbar__links)} onClick={(e) => {
                            e.preventDefault();
                            openLink('/settings');
                        }}>
                            <IconSettings /> {M('components.navbar-home.settings')}
                        </Link>
                    </li> */}
                    <li>
                        <Link
                            href="/"
                            className={classNames(styles.navbar__links)}
                            onClick={(e) => {
                                e.preventDefault();
                                doLogout();
                            }}
                        >
                            <IconLogOut />
                            {M('components.navbar-home.logout')}
                        </Link>
                    </li>
                </ul>
            </div>
        </header>
    );
};

export default Navbar;
