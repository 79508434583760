import React, { useEffect, useState } from 'react';

import i18nConfig from 'i18n.config';

import { useProfile } from '~/contexts/NextProfileProvider';

import { useRouter } from 'next/router';
import useFetch from '~/hooks/useFetch';

import { T_ProfileResponse } from '~/pages/api/profile';
import Head from 'next/head';
import { checkEmptyObject } from '~/common/utils';
import { useProfileService } from '~/hooks/services/useProfileService';
import { MenuDataArray } from '~/storybook/layout/MenuSide';
import SBHomeLayout from '~/storybook/layout/HomeLayout';
import { MFunction, useI18N } from '~/contexts/NextI18NProvider';
import { getWelcomeUrl } from '~/utils/urls';
import { pathTranslations } from '~/i18n/pathTranslations';
import { setCookie } from 'cookies-next';
import { isUserForbiddenFromViewingDiscordLink, isUserForbiddenFromViewingFCAInfo } from '~/utils/regulated-companies';
import { T_PartialProfile, UpdateProfileType } from '~/services/profile';

declare global {
    interface Window {
        darwinexzero: Record<string, any>;
    }
}

type Props = {
    noMenu?: boolean;
    menuData?: (M: MFunction) => MenuDataArray;
    children?: React.ReactComponentElement<any>;
};

const HomeLayout = ({ children, noMenu = false, menuData }: Props) => {
    const nextRouter = useRouter();

    const { M, locale, locales, excludedLoggedLocales, hiddenLocales, U } = useI18N();
    const { profile, setProfile, setPartialProfile, profileRequestFinished } = useProfile();
    const { getProfileInfo } = useProfileService();

    const [logged, setLogged] = useState<boolean | null>(null);

    const getAnchor = () => {
        if (typeof window !== 'undefined') {
            return window.location.hash.substring(1);
        }
        return null;
    };

    const openLink = (link: string | null, samepage: boolean) => {
        const navbarHeight = document.getElementById('navbar')?.offsetHeight ?? 0;
        const container = document.getElementById('home-layout-container');
        if (container) {
            if (!samepage) {
                container.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'auto',
                });
            }
            if (link) {
                const scrollToAnchor = () => {
                    const element =
                        document.querySelector<HTMLElement>(`section[data-anchor=${link}]`) ||
                        document.querySelector<HTMLElement>(`div[data-anchor=${link}]`);
                    if (element) {
                        container.scrollTo({
                            top: element.offsetTop - navbarHeight + 10,
                            left: 0,
                            behavior: 'smooth',
                        });
                    }
                };

                setTimeout(scrollToAnchor, 300);
            }
        }
    };

    const changeAsPath = (asPath: string) => {
        if (nextRouter) {
            nextRouter.replace(asPath);
        }
    };

    useEffect(() => {
        if (nextRouter.isReady) {
            openLink(getAnchor(), false);
        }
    }, [nextRouter.isReady, nextRouter?.pathname, locale]);

    useEffect(() => {
        if (nextRouter.isReady) {
            openLink(getAnchor(), true);
        }
    }, [nextRouter.isReady, nextRouter?.asPath]);

    useEffect(() => {
        // Update global variable
        if (typeof window.darwinexzero !== 'undefined') {
            const darwinexZeroInfo = { ...window.darwinexzero };
            darwinexZeroInfo.isPlatform = false;
            window.darwinexzero = darwinexZeroInfo;
        }
    }, [profileRequestFinished, nextRouter?.pathname]);

    useEffect(() => {
        if (nextRouter.isReady && profileRequestFinished) {
            setLogged(!(!profile || checkEmptyObject(profile)));
        }
    }, [nextRouter.isReady, profileRequestFinished, profile]);

    const { data: logoutData, execute: onLogout } = useFetch('/api/logout', {
        method: 'POST',
    });

    const onLogin = () => {
        nextRouter.push('/login');
    };

    const onRegister = () => {
        nextRouter.push('/signup');
    };

    const onPlatform = () => {
        nextRouter.push(getWelcomeUrl(profile));
    };

    useEffect(() => {
        if (logoutData) {
            setProfile(null as T_ProfileResponse);
            // nextRouter.push('/');
            document.location.replace('/');
        }
    }, [logoutData]);

    // locale
    const [userLocale, setUserLocale] = useState<string | null>(null);
    const { data: localeSettingsData, execute: doLocaleSettings } = useFetch('/api/user-settings', {
        method: 'PATCH',
        body: JSON.stringify({
            locale: userLocale,
        }),
    });

    const updateLocalePath = (newLocale: string, isGuest?: boolean) => {
        if (isGuest) {
            const dateToken = new Date();
            dateToken.setTime(dateToken.getTime() + 24 * 60 * 60 * 1000);

            setCookie('GUEST_LOCALE', newLocale, {
                path: '/',
                sameSite: 'lax',
                httpOnly: false,
                expires: dateToken,
                secure: true,
            });
        }

        const { pathname, query, asPath } = nextRouter;
        const { untranslatedLocalesPath } = i18nConfig;

        // Get translated route for non-default locales
        const translatedPath = untranslatedLocalesPath.includes(newLocale)
            ? pathname
            : pathTranslations[newLocale]?.[pathname];
        // Set `as` prop to change displayed URL in browser
        const parameterToRemove = 'lang';
        const removeParameterFromURL = (url: string, parameterToRemove: string) => {
            const urlObj = new URL(`https://www.darwinexzero.com${url}`); // Add a temporary domain
            urlObj.searchParams.delete(parameterToRemove);
            return urlObj.pathname + urlObj.search;
        };
        const as = translatedPath
            ? `${newLocale === 'en' ? '' : `/${newLocale}`}${translatedPath}`
            : removeParameterFromURL(asPath, parameterToRemove);

        const newQuery: Record<string, any> = { ...query };
        if (typeof newQuery.lang !== 'undefined') {
            newQuery.lang = newLocale;
        }
        nextRouter.push({ pathname: translatedPath, query: newQuery }, as, { locale: newLocale });
    };

    useEffect(() => {
        if (userLocale) {
            if (logged) {
                doLocaleSettings();
            } else {
                updateLocalePath(userLocale, true);
            }
        }
    }, [userLocale]);

    useEffect(() => {
        if (localeSettingsData) {
            if (userLocale) {
                updateLocalePath(userLocale);
            }
            doGetUserData();
        }
    }, [localeSettingsData]);

    const { data: userData, execute: doGetUserData } = getProfileInfo<T_PartialProfile>([UpdateProfileType.USER_INFO]);

    useEffect(() => {
        if (userData) {
            setPartialProfile(userData);
        }
    }, [userData]);

    const localesDropdown = locales.filter((lo) => !hiddenLocales.includes(lo));
    const localesAvailable = !logged
        ? localesDropdown
        : localesDropdown.filter((x) => !excludedLoggedLocales.includes(x));

    const requestOriginCountry = children?.props?.requestOriginCountry;
    const showFCAText = !isUserForbiddenFromViewingFCAInfo([
        requestOriginCountry,
        profile?.country,
        profile?.KYS?.info?.address?.country,
    ]);
    const showDiscordIcon = !isUserForbiddenFromViewingDiscordLink([requestOriginCountry]);

    return (
        <SBHomeLayout
            noMenu={noMenu}
            onLogin={onLogin}
            onRegister={onRegister}
            onPlatform={onPlatform}
            doLogout={onLogout}
            profile={profile}
            logged={logged}
            openLink={(path) => nextRouter.push(U(path))}
            onChangeLocale={(newLocale: string) => setUserLocale(newLocale)}
            localesAvailable={localesAvailable}
            locale={locale}
            menuData={menuData && menuData(M)}
            changeAsPath={changeAsPath}
            showFCAText={showFCAText}
            showDiscordIcon={showDiscordIcon}
        >
            <Head>
                <meta property="og:type" content="website" key="og_type" />
            </Head>

            {children}
        </SBHomeLayout>
    );
};

export default HomeLayout;
